
import { Component, Prop, Vue } from "vue-property-decorator";
import { ILoadingPlan } from "@/types/LoadingPlan";
const LoadingPlanProps = Vue.extend({
  props: {
    modal: Object,
    edit: Object,
    loadingPlan: Object,
    transporters: [],
    commodities: [],
    warehouses: [],
    districts: [],
  },
});
@Component({
  name: "LoadingPlanModal",
  components: {},
})
export default class LoadingPlanModal extends LoadingPlanProps {
  transporterState = null;
  monthState = null;
  commodityState = null;
  quantityState = null;
  warehouseState = null;
  districtState = null;
  startDateState = null;
  endDateState = null;
  nameState = null;
  name = "";
  isDisabled = false;

  update(): void {
    this.loadingPlan.balance = this.balance;
    this.$emit("update:loading-plan", this.loadingPlan);
  }
  get balance(): number {
    return +((this.loadingPlan.quantity - this.modal.dispatched).toFixed(2));
  }
}
